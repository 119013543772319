import * as React from "react";
import axios from "axios";
import { Box } from "@mui/material";
import theme from "./theme";
import CardCategory from "./component/CardCategory";
import { LoginContext } from "./AppContext";

function ChiTieu(props) {
  const login = React.useContext(LoginContext);
  const [data, setData] = React.useState(null);

  const get_data = () => {
    axios({
      method: "get",
      url: process.env.REACT_APP_API_ENDPOINT + "report_cat",
      headers: { Authorization: login.token },
    })
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          login.set_token(null);
        }
      });
  };

  React.useEffect(() => {
    get_data();
  }, []);

  return (
    <div
      style={{ backgroundColor: theme.palette.color_map.gray, height: "100vh" }}
    >
      {data == null
        ? null
        : data.data.map((item) => <CardCategory data={item} />)}
      <Box style={{ height: 100 }}></Box>
    </div>
  );
}

export default ChiTieu;
