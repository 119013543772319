import * as React from "react";
import { Card, CardContent, Typography, Box, Divider } from "@mui/material";
import theme from "../theme";
import RenderCategory from "../RenderCategory";
import render_number from "../util";
import data_const from "../constant";
import { LoginContext } from "../AppContext";
import axios from "axios";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function App(props) {
  const login = React.useContext(LoginContext);

  let item = props.data;

  const [dataLine, setDataLine] = React.useState(null);
  const [showData, setShowData] = React.useState(false);

  const get_data_h = (category_id) => {
    if (dataLine == null) {
      axios({
        method: "get",
        url: process.env.REACT_APP_API_ENDPOINT + "report_cat/" + category_id,
        headers: { Authorization: login.token },
      })
        .then((data) => {
          let labels_arr = [];
          let data_arr = [];
          let borderColor = "rgb(255, 99, 132)";
          let backgroundColor = "rgba(255, 99, 132, 0.5)";
          if (item.c_type == data_const.TRANSACTION_IN) {
            borderColor = "#AED581";
            backgroundColor = "#CCFF90";
          }

          data.data.data.map((itemx) => {
            labels_arr.push(itemx.date_month + "/" + itemx.date_year);
            data_arr.push(itemx.sum_money);
          });

          console.log(labels_arr, data_arr);

          setDataLine({
            labels: labels_arr,
            datasets: [
              {
                data: data_arr,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
              },
            ],
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            login.set_token(null);
          }
        });
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
  };

  return (
    <Box
      style={{
        backgroundColor: theme.palette.color_map.gray,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
      }}
      onClick={() => {
        get_data_h(item.c_id);

        setShowData(!showData);
      }}
    >
      <Card>
        <CardContent>
          <Box style={style_detail.box4}>
            <Box style={style_detail.box3}>{RenderCategory(item.c_id)}</Box>
            <Box style={style_detail.box2}>{item.c_name}</Box>

            <Box style={style_detail.box1}>
              <Typography
                style={
                  item.c_type == data_const.TRANSACTION_IN
                    ? theme.palette.congtien
                    : theme.palette.trutien
                }
              >
                {render_number(item.sum_money)}
              </Typography>
            </Box>
          </Box>
        </CardContent>

        <Divider />

        {showData ? (
          dataLine != null ? (
            <Line options={options} data={dataLine} />
          ) : null
        ) : null}
      </Card>
    </Box>
  );
}

const style_detail = {
  box1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // paddingBottom: 100,
    flex: 2,
  },
  box2: {
    flex: 5,
    display: "flex",
    alignItems: "center",
  },
  box3: {
    display: "flex",
    flex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  box4: {
    display: "flex",
    justifyContent: "space-between",
  },
};
