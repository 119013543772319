import FastfoodIcon from "@mui/icons-material/Fastfood";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import OpacityIcon from "@mui/icons-material/Opacity";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CellWifiIcon from "@mui/icons-material/CellWifi";

import HouseIcon from "@mui/icons-material/House";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import BuildIcon from "@mui/icons-material/Build";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DevicesIcon from "@mui/icons-material/Devices";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import SchoolIcon from "@mui/icons-material/School";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import HikingIcon from "@mui/icons-material/Hiking";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";

const danh_muc = [
  [
    { name: "Ăn uống", id: 1, category: 1, icon: <FastfoodIcon /> },
    { name: "Cafe", id: 2, category: 1, icon: <EmojiFoodBeverageIcon /> },
  ],
  [
    { name: "Hóa đơn", id: 3, category: 2, icon: <ReceiptLongIcon /> },
    { name: "Tiền điện", id: 4, category: 2, icon: <ElectricalServicesIcon /> },
    { name: "Tiền nước", id: 5, category: 2, icon: <OpacityIcon /> },
    { name: "Điện thoại", id: 6, category: 2, icon: <PhoneAndroidIcon /> },
    { name: "Internet", id: 7, category: 2, icon: <CellWifiIcon /> },
    { name: "Thuê nhà", id: 8, category: 2, icon: <HouseIcon /> },
  ],
  [
    {
      name: "Di chuyển",
      id: 9,
      category: 3,
      icon: <DirectionsCarFilledIcon />,
    },
    { name: "Gửi xe", id: 10, category: 3, icon: <LocalParkingIcon /> },
    { name: "Xăng dầu", id: 11, category: 3, icon: <LocalGasStationIcon /> },
    { name: "Bảo dưỡng", id: 12, category: 3, icon: <BuildIcon /> },
  ],
  [
    { name: "Mua sắm", id: 13, category: 4, icon: <StorefrontIcon /> },
    { name: "Quần áo", id: 14, category: 4, icon: <CheckroomIcon /> },
    { name: "Thiết bị điện tử", id: 15, category: 4, icon: <DevicesIcon /> },
  ],
  [
    { name: "Người yêu", id: 16, category: 5, icon: <FavoriteIcon /> },
    { name: "Du lịch", id: 17, category: 6, icon: <HikingIcon /> },
    { name: "Sức khỏe", id: 18, category: 7, icon: <MedicalInformationIcon /> },
    { name: "Hiếu hỉ", id: 19, category: 8, icon: <AttachMoneyIcon /> },
    { name: "Gia đình", id: 20, category: 9, icon: <FamilyRestroomIcon /> },
    { name: "Con cái", id: 21, category: 9, icon: <ChildFriendlyIcon /> },
    { name: "Sửa nhà", id: 22, category: 9, icon: <BuildIcon /> },
    { name: "Giáo dục", id: 23, category: 10, icon: <SchoolIcon /> },
    { name: "Khác", id: 24, category: 11, icon: <AttachMoneyIcon /> },
  ],
];

export default danh_muc;
