import axios from "axios";
import * as React from "react";
import {
  TextField,
  Card,
  Typography,
  Box,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import danh_muc_assets from "./CategoryAsset";
import SaveBtn from "./component/SaveBtn";
import render_number from "./util";
import { LoginContext } from "./AppContext";

export default function App(props) {
  const login = React.useContext(LoginContext);
  let data = props.data;

  let danh_muc_init = danh_muc_assets;
  let so_tien_init = render_number(data.money);

  const [so_tien, set_so_tien] = React.useState(so_tien_init);

  let category_init = {
    id: data.c_id,
    name: data.c_name,
    type: data.c_type,
  };
  const [category, set_category] = React.useState(category_init);
  const [note, setNote] = React.useState(data.note);
  const [showDanhmuc, set_showDanhmuc] = React.useState(false);
  const [name, setName] = React.useState(data.name);
  const [is_finish, set_is_finish] = React.useState(false);

  const edit_chi_tieu = () => {
    var data_body = {
      money: Number(so_tien.replaceAll(/,/g, "")),
      note: note,
      category_id: category.id,
      name: name,
      is_finish: is_finish ? 1 : 0,
    };

    var config = {
      method: "patch",
      url: process.env.REACT_APP_API_ENDPOINT + "asset/" + data.id,
      headers: { Authorization: login.token },
      data: data_body,
    };

    axios(config)
      .then((data) => {
        if (!data.data.error) {
          props.onSaveClick();
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          login.set_token(null);
        }
      });
  };

  const delete_chitieu = () => {
    var config = {
      method: "delete",
      url: process.env.REACT_APP_API_ENDPOINT + "asset/" + props.data.id,
      headers: { Authorization: login.token },
    };

    axios(config)
      .then((data) => {
        if (!data.data.error) {
          props.onSaveClick();
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          login.set_token(null);
        }
      });
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 10,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={() => delete_chitieu()}
        >
          DELETE
        </Button>
      </Box>

      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Tên</Typography>
        <TextField
          style={{ flex: 1 }}
          fullWidth
          name="Name"
          placeholder="Tên"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>

      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Số tiền</Typography>
        <TextField
          autoFocus
          style={{ flex: 1 }}
          fullWidth
          name="So tien"
          placeholder="Số tiền"
          value={so_tien}
          onChange={(e) => {
            let a = e.target.value;
            a = a.replaceAll(/,/g, "");
            a = Number(a)
              .toFixed(1)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .replace(".0", "");
            set_so_tien(a);
          }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
        />
      </Box>

      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Danh mục chi tiêu</Typography>
        <Box style={{ flex: 1 }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => set_showDanhmuc(true)}
          >
            <Typography>
              {category == null ? "Lựa chọn" : category.name}
            </Typography>
          </Button>
        </Box>
      </Box>

      {showDanhmuc
        ? danh_muc_init.map((itemx) => {
            return (
              <Card style={{ margin: 5 }}>
                <Grid container>
                  {itemx.map((item) => {
                    return (
                      <Grid
                        xs={5}
                        sx={style_detail.danhmucItem}
                        onClick={() => {
                          set_showDanhmuc(false);
                          set_category({
                            name: item.name,
                            id: item.id,
                            type: item.category,
                          });
                        }}
                      >
                        <Box style={{ paddingRight: 10 }}>{item.icon}</Box>
                        <Typography>{item.name}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Card>
            );
          })
        : null}

      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Ghi chú</Typography>
        <TextField
          style={{ flex: 1 }}
          fullWidth
          name="Note"
          placeholder="Note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </Box>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={is_finish}
              onChange={() => set_is_finish(!is_finish)}
              defaultChecked
            />
          }
          label="Kết thúc"
        />
      </FormGroup>

      {/* <Box style={style_detail.btn_save}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          style={{ marginTop: 50 }}
          onClick={() => props.onDiscardClick()}
        >
          Hủy thay đổi
        </Button>

        {category == null ? null : (
          <Button
            style={{ marginTop: 50 }}
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            onClick={() => edit_chi_tieu()}
          >
            SAVE
          </Button>
        )}
      </Box> */}

      <SaveBtn
        onClickSave={() => edit_chi_tieu()}
        onClickCancle={() => props.onDiscardClick()}
        showSave={category}
      />
    </Box>
  );
}

const style_detail = {
  // btn_save: {
  //   margin: 10,
  // },
  boxItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 10,
  },
  danhmucItem: {
    flexDirection: "row",
    display: "flex",
    margin: 1,
    padding: 1,
  },
};
