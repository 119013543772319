import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import data_const from "../constant";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SavingsIcon from "@mui/icons-material/Savings";

export default function ProTip(props) {
  const [value, setValue] = React.useState(props.active_tab);

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          console.log(newValue);
          setValue(newValue);
          props.change_nav(newValue);
        }}
      >
        <BottomNavigationAction
          label="Chi tiêu"
          value={data_const.chi_tieu}
          icon={<MonetizationOnIcon />}
        />
        <BottomNavigationAction
          label="Tài sản"
          value={data_const.asset}
          icon={<SavingsIcon />}
        />
        <BottomNavigationAction
          label="Báo cáo"
          value={data_const.bao_cao}
          icon={<HistoryEduIcon />}
        />
        <BottomNavigationAction
          label="User"
          value={data_const.user}
          icon={<AccountCircleIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}
