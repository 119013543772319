import * as React from "react";
import data_const from "./constant";
import { Avatar, Box, Typography, TextField, Button } from "@mui/material";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
import { LoginContext } from "./AppContext";

import Image1 from "./images/w1.png";
import Image2 from "./images/w2.png";
import Image3 from "./images/w3.png";
import Image4 from "./images/w4.png";
import Image5 from "./images/w5.png";

export default function App() {
  const login = React.useContext(LoginContext);
  const user_init = localStorage.getItem(data_const.MONOTE_USER_ID);
  const [user_id, set_user_id] = React.useState(user_init);
  const [create_password, set_create_password] = React.useState(true);
  const [password, set_password] = React.useState("");
  const [password_2, set_password_2] = React.useState("");
  const [wrong_password, set_wrong_password] = React.useState(false);
  const [image_url, set_image_url] = React.useState(`url(${Image1})`);

  const responseGoogle_success = (result) => {
    set_user_id(result.googleId);

    localStorage.setItem(data_const.MONOTE_USER_ID, result.googleId);
    localStorage.setItem(data_const.MONOTE_USER_NAME, result.profileObj.name);
    localStorage.setItem(
      data_const.MONOTE_USER_IMAGE,
      result.profileObj.imageUrl
    );

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT + "user/signup",
      data: {
        name: result.profileObj.name,
        google_id: result.googleId,
        image_url: result.profileObj.imageUrl,
        email: result.profileObj.email,
      },
    };

    axios(config).then((result) => {
      set_create_password(result.data.create_password);
    });
  };

  const loginFunc = () => {
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT + "user/login",
      data: { google_id: user_id, password: password },
    };

    axios(config)
      .then((result) => {
        if (!result.data.error) {
          login.set_token(result.data.token);
        } else {
          set_wrong_password(true);
          set_password("");
        }
      })
      .catch((err) => console.log("error", err));
  };

  const send_new_password = () => {
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT + "user/set_password",
      data: { google_id: user_id, password: password },
    };

    axios(config).then((result) => {
      if (!result.data.error) {
        login.set_token(result.data.token);
      }
    });
  };

  const gen_create_pass = () => {
    return (
      <Box style={{ display: "flex", flexDirection: "column", marginTop: 50 }}>
        <TextField
          autoFocus
          name="password"
          type="password"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
          placeholder="password"
          value={password}
          onChange={(e) => {
            set_password(e.target.value);
          }}
        />
        <TextField
          name="password"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
          type="password"
          placeholder="confirm password"
          value={password_2}
          onChange={(e) => {
            set_password_2(e.target.value);
          }}
        />
        {password == password_2 && password != "" ? (
          <Button onClick={() => send_new_password()}>create password</Button>
        ) : null}
      </Box>
    );
  };

  const gen_send_password = () => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 20,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField
          name="password"
          autoFocus
          type="password"
          placeholder="password"
          value={password}
          onChange={(e) => set_password(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode == 13) {
              console.log("enter");
              loginFunc();
            }
          }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
        />
        {wrong_password == true ? <Typography>Sai password</Typography> : null}
        <Button onClick={() => loginFunc()}>Login</Button>
      </Box>
    );
  };

  const Logout = () => {
    set_user_id(null);
    set_password("");
    set_password_2("");
    localStorage.removeItem(data_const.MONOTE_USER_ID);
    localStorage.removeItem(data_const.MONOTE_ACCESS_TOKEN);
    localStorage.removeItem(data_const.MONOTE_USER_NAME);
  };

  const pre_login = () => {
    if (user_id != null) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_API_ENDPOINT + "user/pre_login/" + user_id,
      };

      axios(config).then((result) => {
        // console.log(result);
        result = result.data;
        set_create_password(result.create_password);
      });
    }
  };

  React.useEffect(() => {
    pre_login();

    let random = Math.floor(Math.random() * 5 + 1);
    if (random == 1) {
      set_image_url(`url(${Image1})`);
    } else if (random == 2) {
      set_image_url(`url(${Image2})`);
    } else if (random == 3) {
      set_image_url(`url(${Image3})`);
    } else if (random == 4) {
      set_image_url(`url(${Image4})`);
    } else if (random == 5) {
      set_image_url(`url(${Image5})`);
    }
  }, []);

  const responseGoogle_failure = (err) => {
    console.log(err);
  };

  return (
    <div
      style={{
        backgroundImage: image_url,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      {user_id == null ? (
        <Box style={{ marginTop: 230 }}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GG_CLIENT}
            buttonText="Login"
            onSuccess={responseGoogle_success}
            onFailure={responseGoogle_failure}
            cookiePolicy={"single_host_origin"}
          />
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 200,
          }}
        >
          <Avatar
            alt="User"
            src={localStorage.getItem(data_const.MONOTE_USER_IMAGE)}
            sx={{ width: 80, height: 80 }}
          />
          <Typography>
            {localStorage.getItem(data_const.MONOTE_USER_NAME)}
          </Typography>
          {create_password ? gen_create_pass() : gen_send_password()}

          <Button style={{ marginTop: 30 }} onClick={() => Logout()}>
            Logout
          </Button>
        </Box>
      )}
    </div>
  );
}
