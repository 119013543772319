import * as React from "react";
import {
  Card,
  Box,
  SpeedDial,
  Dialog,
  Divider,
  Typography,
} from "@mui/material";
import { LoginContext } from "./AppContext";
import axios from "axios";
import CardAsset from "./component/CardAsset";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import theme from "./theme";
import AddAsset from "./AddAsset";
import EditAsset from "./EditAsset";
import render_number from "./util";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ChiTieu(props) {
  const login = React.useContext(LoginContext);
  const [data, setData] = React.useState(null);
  const [open_dialog, set_open_dialog] = React.useState(false);
  const [open_dialog_edit, set_open_dialog_edit] = React.useState(false);
  const [data_edit, set_data_edit] = React.useState(null);
  const [data_info, set_data_info] = React.useState(null);
  const [data_chart, set_data_chart] = React.useState(null);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        display: false,
      },
      title: {
        display: true,
        text: "Tài sản",
      },
    },
  };

  const get_data = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_ENDPOINT + "asset",
      headers: { Authorization: login.token },
    };

    axios(config)
      .then((data) => {
        data = data.data;
        if (!data.error) {
          setData(data.data);
          set_data_info(data.info);

          let labels = [
            "Tiền mặt",
            "Tiết kiệm",
            "Đầu tư",
            "Cho vay",
            "Nợ",
            "Tổng",
          ];

          let data_chart = {
            labels,
            datasets: [
              {
                data: [
                  [0, data.info.sum_tienmat],
                  [
                    data.info.sum_tienmat,
                    data.info.sum_tienmat + data.info.sum_tk,
                  ],
                  [
                    data.info.sum_tienmat + data.info.sum_tk,
                    data.info.sum_tienmat +
                      data.info.sum_tk +
                      data.info.sum_dautu,
                  ],
                  [
                    data.info.sum_tienmat +
                      data.info.sum_tk +
                      data.info.sum_dautu,
                    data.info.sum_tienmat +
                      data.info.sum_tk +
                      data.info.sum_dautu +
                      data.info.sum_chovay,
                  ],
                  [
                    data.info.sum_tienmat +
                      data.info.sum_tk +
                      data.info.sum_dautu +
                      data.info.sum_chovay,
                    data.info.sum_tienmat +
                      data.info.sum_tk +
                      data.info.sum_dautu +
                      data.info.sum_chovay -
                      data.info.sum_no,
                  ],
                  [
                    0,
                    data.info.sum_tienmat +
                      data.info.sum_tk +
                      data.info.sum_dautu +
                      data.info.sum_chovay -
                      data.info.sum_no,
                  ],
                ],
                backgroundColor: theme.palette.list_borderColor,
                // borderColor: theme.palette.list_borderColor,
              },
            ],
          };

          set_data_chart(data_chart);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          login.set_token(null);
        }
      });
  };

  React.useEffect(() => {
    get_data();
  }, []);

  return (
    <div
      style={{
        backgroundColor: theme.palette.color_map.gray,
        height: "100vh",
        paddingTop: 10,
      }}
    >
      <Card
        style={{
          backgroundColor: "white",
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        {data_chart == null ? null : (
          <Bar options={options} data={data_chart} />
        )}
      </Card>

      {data_info == null ? null : (
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 50,
            paddingRight: 30,
            paddingTop: 10,
            paddingBottom: 10,
            margin: 10,
          }}
        >
          <div style={{ flex: 1 }}>
            <Typography>Tổng tài sản:</Typography>
            <Divider />
            <Typography>Tiền mặt:</Typography>
            <Typography>Tiết kiệm:</Typography>
            <Typography>Đầu tư:</Typography>
            <Typography>Cho vay:</Typography>
            <Divider />
            <Typography>Nợ:</Typography>
          </div>
          <div style={{ flex: 1, alignItems: "flex-end" }}>
            <Typography>{render_number(data_info.sum_asset)}</Typography>
            <Divider />
            <Typography>{render_number(data_info.sum_tienmat)}</Typography>
            <Typography>{render_number(data_info.sum_tk)}</Typography>
            <Typography>{render_number(data_info.sum_dautu)}</Typography>
            <Typography>{render_number(data_info.sum_chovay)}</Typography>
            <Divider />

            <Typography>{render_number(data_info.sum_no)}</Typography>
          </div>
        </Card>
      )}

      {data == null ? null : (
        <CardAsset
          data={data}
          onClickEdit={(data) => {
            set_data_edit(data);
            set_open_dialog_edit(true);
          }}
        />
      )}

      <Box onClick={() => set_open_dialog(!open_dialog)}>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={style_detail.add_icon}
          icon={<SpeedDialIcon />}
        ></SpeedDial>
      </Box>

      <Dialog
        fullScreen
        open={open_dialog_edit}
        onClose={() => set_open_dialog_edit(false)}
        scroll={"paper"}
      >
        <EditAsset
          onSaveClick={() => {
            set_open_dialog_edit(false);

            setTimeout(() => {
              get_data();
            }, 500);
          }}
          onDiscardClick={() => set_open_dialog_edit(false)}
          data={data_edit}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={open_dialog}
        onClose={() => set_open_dialog(false)}
        scroll={"paper"}
      >
        <AddAsset
          onDiscardClick={() => set_open_dialog(false)}
          onSaveClick={() => {
            set_open_dialog(false);
            setTimeout(() => {
              get_data();
            }, 500);
          }}
        />
      </Dialog>
      <Box
        style={{
          backgroundColor: theme.palette.color_map.gray,
          height: 60,
        }}
      ></Box>
    </div>
  );
}

const style_detail = {
  add_icon: {
    position: "fixed",
    bottom: 40,
    right: "42%",
  },
};
export default ChiTieu;
