import * as React from "react";
import {
  Box,
  Divider,
  FormControl,
  Tab,
  Tabs,
  Select,
  MenuItem,
} from "@mui/material";

import theme from "../theme";

export default function SelectDate(props) {
  return (
    <Box style={style_detail.box_to} elevation={5}>
      <Box
        style={{
          display: "flex",
          backgroundColor: "white",
          borderRadius: 7,
          alignItems: "center",
        }}
      >
        <Tabs
          style={{ flex: 6 }}
          value={props.date_month}
          onChange={(event, newValue) => props.onChangeMonth(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Tháng 1" />
          <Tab label="Tháng 2" />
          <Tab label="Tháng 3" />
          <Tab label="Tháng 4" />
          <Tab label="Tháng 5" />
          <Tab label="Tháng 6" />
          <Tab label="Tháng 7" />
          <Tab label="Tháng 8" />
          <Tab label="Tháng 9" />
          <Tab label="Tháng 10" />
          <Tab label="Tháng 11" />
          <Tab label="Tháng 12" />
        </Tabs>

        <Box style={style_detail.chon_nam}>
          <FormControl sx={{ minWidth: 60 }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={props.date_year}
              onChange={(e) => props.onChangeYear(e)}
            >
              <MenuItem value={2021}>2021</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}

const style_detail = {
  add_icon: {
    position: "fixed",
    bottom: 40,
    right: "42%",
  },
  box_to: {
    position: "fixed",
    backgroundColor: theme.palette.color_map.gray,
    padding: 10,
    top: 0,
    left: 0,
    right: 0,
  },
  chon_nam: {
    flex: 1,
    display: "flex",
    alightItem: "center",
    justifyContent: "center",
    paddingRight: 5,
  },
};
