import SavingsIcon from "@mui/icons-material/Savings";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

const danh_muc = [
  [
    { name: "Tiết kiệm", id: 31, category: 5, icon: <SavingsIcon /> },
    { name: "Đầu tư", id: 32, category: 6, icon: <TrendingUpIcon /> },
    { name: "Cho vay", id: 33, category: 7, icon: <LocalAtmIcon /> },
    { name: "Nợ", id: 34, category: 7, icon: <TrendingDownIcon /> },
  ],
];

export default danh_muc;
