const data_const = {
  user: "user",
  wallet: "wallet",
  thu_nhap: "thu_nhap",
  chi_tieu: "chi_tieu",
  bao_cao: "bao_cao",
  chi_tieu: "chi_tieu",
  add_chi_tieu: "add_chi_tieu",
  asset: "asset",

  // localstorage
  MONOTE_USER_ID: "MONOTE_USER_ID",
  MONOTE_ACCESS_TOKEN: "MONOTE_ACCESS_TOKEN",
  MONOTE_USER_NAME: "MONOTE_USER_NAME",
  MONOTE_USER_IMAGE: "MONOTE_USER_IMAGE",
  MONOTE_WALLET_ID: "MONOTE_WALLET_ID",

  TRANSACTION_IN: "IN",
  TRANSACTION_OUT: "OUT",
  TRANSACTION_INIT: "INIT",
  ASSET_INSIDE: "ASSET_INSIDE",
  ASSET_OUTSIDE: "ASSET_OUTSIDE",
};
export default data_const;
