import axios from "axios";
import * as React from "react";
import { TextField, Card, Typography, Box, Grid, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import danh_muc from "./CategoryAsset";
import SaveBtn from "./component/SaveBtn";
import { LoginContext } from "./AppContext";

export default function App(props) {
  const login = React.useContext(LoginContext);

  const [category, set_category] = React.useState(null);
  const [so_tien, set_so_tien] = React.useState(null);
  const [note, setNote] = React.useState("");
  const [name, setName] = React.useState("");

  const [showDanhmuc, set_showDanhmuc] = React.useState(false);

  const add_asset = () => {
    var data_body = {
      name: name,
      money: Number(so_tien.replaceAll(/,/g, "")),
      note: note,
      category_id: category.id,
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT + "asset",
      headers: { Authorization: login.token },
      data: data_body,
    };

    axios(config)
      .then((data) => {
        if (!data.data.error) {
          props.onSaveClick();
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          login.set_token(null);
        }
      });
  };

  const render_danh_muc = (itemx) => {
    return (
      <Card style={{ margin: 5 }}>
        <Grid container>
          {itemx.map((item) => {
            return (
              <Grid
                xs={5}
                sx={style_detail.danhmucItem}
                onClick={() => {
                  set_showDanhmuc(false);
                  set_category({
                    name: item.name,
                    id: item.id,
                    type: item.category,
                  });
                }}
              >
                <Box style={{ paddingRight: 10 }}>{item.icon}</Box>
                <Typography>{item.name}</Typography>
              </Grid>
            );
          })}
        </Grid>
      </Card>
    );
  };

  return (
    <Box>
      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Tên</Typography>
        <TextField
          style={{ flex: 1 }}
          fullWidth
          name="Name"
          placeholder="Tên"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Số tiền</Typography>
        <TextField
          autoFocus
          style={{ flex: 1 }}
          fullWidth
          name="So tien"
          placeholder="Số tiền"
          value={so_tien}
          onChange={(e) => {
            let a = e.target.value;
            a = a.replaceAll(/,/g, "");
            a = Number(a)
              .toFixed(1)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .replace(".0", "");
            set_so_tien(a);
          }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
        />
      </Box>

      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Danh mục tài sản</Typography>
        <Box style={{ flex: 1 }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => set_showDanhmuc(true)}
          >
            <Typography>
              {category == null ? "Lựa chọn" : category.name}
            </Typography>
          </Button>
        </Box>
      </Box>

      {showDanhmuc ? danh_muc.map((itemx) => render_danh_muc(itemx)) : null}
      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Ghi chú</Typography>
        <TextField
          style={{ flex: 1 }}
          fullWidth
          name="Note"
          placeholder="Note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </Box>

      <SaveBtn
        onClickSave={() => add_asset()}
        onClickCancle={() => props.onDiscardClick()}
        showSave={category}
      />
    </Box>
  );
}

const style_detail = {
  boxItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 10,
  },
  danhmucItem: {
    flexDirection: "row",
    display: "flex",
    margin: 1,
    padding: 1,
  },
};
