import * as React from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";
import theme from "../theme";
import RenderCategory from "../RenderCategory";
import render_number from "../util";
import data_const from "../constant";

export default function App(props) {
  //   const [category, set_category] = React.useState(props.showSave);

  return (
    <Box style={style_detail.btn_save}>
      <Button
        fullWidth
        variant="outlined"
        color="error"
        style={style_detail.btn_child}
        onClick={() => props.onClickCancle()}
      >
        Hủy thay đổi
      </Button>

      {props.showSave == null ? null : (
        <Button
          style={style_detail.btn_child}
          fullWidth
          variant="contained"
          size="large"
          color="primary"
          onClick={() => props.onClickSave()}
        >
          SAVE
        </Button>
      )}
    </Box>
  );
}

const style_detail = {
  btn_save: {
    margin: 10,
    display: "flex",
  },
  btn_child: {
    margin: 15,
  },
};
