import * as React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { LoginContext, LoginContextProvider } from "./AppContext";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />

    <LoginContextProvider>
      <App />
    </LoginContextProvider>
  </ThemeProvider>
);
