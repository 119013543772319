import * as React from "react";

const login_token_str = "";

export const LoginContext = React.createContext({
  set_token: () => {},
  token: login_token_str,
});

export const LoginContextProvider = (props) => {
  const set_token = (token) => {
    setState({ ...state, token: token });
  };

  const initialState = {
    set_token: set_token,
    token: login_token_str,
  };

  const [state, setState] = React.useState(initialState);

  return (
    <LoginContext.Provider value={state}>
      {props.children}
    </LoginContext.Provider>
  );
};
