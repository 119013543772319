import * as React from "react";
import { Box, Divider, Tab, Tabs, Card } from "@mui/material";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import LunchDiningTwoToneIcon from "@mui/icons-material/LunchDiningTwoTone";

export default function SelectTypeReport(props) {
  return (
    <Card
      style={{
        backgroundColor: "white",
        marginLeft: 10,
        marginRight: 10,
      }}
    >
      <Box style={{ display: "flex" }}>
        <Tabs
          style={{
            flex: 6,
            minHeight: 10,
            height: 40,

            alignItems: "center",
            justifyContent: "center",
          }}
          value={props.type_report}
          onChange={(event, newValue) => props.onChangeValue(newValue)}
          // variant="scrollable"
          variant="fullWidth"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab
            icon={<CalendarMonthTwoToneIcon />}
            iconPosition="right"
            label="Date"
          />
          <Tab
            icon={<LunchDiningTwoToneIcon />}
            iconPosition="right"
            label="Category"
          />
          {/* <Tab
            icon={<FindInPageTwoToneIcon />}
            iconPosition="top"
            label="Tìm kiếm"
          /> */}
        </Tabs>
      </Box>
      <Divider />
    </Card>
  );
}
