import StarsIcon from "@mui/icons-material/Stars";
import PercentIcon from "@mui/icons-material/Percent";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const danh_muc = [
  [
    { name: "Lương", id: 25, category: -1, icon: <MonetizationOnIcon /> },
    { name: "Thưởng", id: 26, category: -2, icon: <StarsIcon /> },
    { name: "Tiền lãi", id: 27, category: -3, icon: <PercentIcon /> },
  ],
];
export default danh_muc;
