import * as React from "react";
import theme from "./theme";
import SelectTypeReport from "./component/SelectTypeReport";
import BaoCaoThang from "./BaoCaoThang";
import BaoCaoLoai from "./BaoCaoLoai";

function ChiTieu(props) {
  const [type_report, set_type_report] = React.useState(0);

  const render_type = () => {
    if (type_report == 0) {
      return <BaoCaoThang />;
    } else if (type_report == 1) {
      return <BaoCaoLoai />;
    } else {
      return null;
    }
  };

  return (
    <div
      style={{
        backgroundColor: theme.palette.color_map.gray,
        height: "100vh",
        paddingTop: 10,
      }}
    >
      <SelectTypeReport
        type_report={type_report}
        onChangeValue={(new_value) => set_type_report(new_value)}
      />
      {render_type()}
    </div>
  );
}

export default ChiTieu;
