import * as React from "react";
import Navbar from "./component/Navbar";
import data_const from "./constant";
import ChiTieuHistory from "./ChiTieuHistory";
import Login from "./Login";
import User from "./User";
import BaoCao from "./BaoCao";
import Assets from "./Asset";
import { LoginContext } from "./AppContext";

export default function App() {
  const login = React.useContext(LoginContext);
  const [active_tab, set_active_tab] = React.useState(data_const.chi_tieu);
  const render_tab = (active_tab) => {
    if (active_tab == data_const.chi_tieu) {
      return <ChiTieuHistory />;
    } else if (active_tab == data_const.asset) {
      return <Assets />;
    } else if (active_tab == data_const.bao_cao) {
      return <BaoCao />;
    } else if (active_tab == data_const.user) {
      return <User />;
    }
  };

  return (
    <div>
      {login.token == null ? (
        <Login />
      ) : (
        <div>
          {render_tab(active_tab)}
          <Navbar
            active_tab={active_tab}
            change_nav={(navbar_val) => set_active_tab(navbar_val)}
          />
        </div>
      )}
    </div>
  );
}
