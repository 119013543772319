import * as React from "react";
import { Button, Box, TextField, Card, Typography } from "@mui/material";
import data_const from "./constant";
import { LoginContext } from "./AppContext";
import theme from "./theme";
import axios from "axios";

export default function App(props) {
  const login = React.useContext(LoginContext);

  const [password, set_password] = React.useState("");
  const [password_1, set_password_1] = React.useState("");
  const [password_2, set_password_2] = React.useState("");

  const [change_nav_state, set_nav_state] = React.useState("");

  const [data_wallet, set_data_wallet] = React.useState(null);
  const [new_wallet_name, set_wallet_name] = React.useState("");

  const change_password_api = () => {
    console.log("confirmed");
    var data_body = {
      password: password,
      newpassword: password_1,
    };

    var config = {
      method: "post",
      headers: { Authorization: login.token },
      data: data_body,
      url: process.env.REACT_APP_API_ENDPOINT + "user/change_password",
    };

    axios(config)
      .then((result) => {
        if (result.data.error == false) {
          set_password("");
          set_password_1("");
          set_password_2("");
          set_nav_state("change password ok");
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          set_nav_state("current password is invalid");
        }
      });
  };

  const get_data_wallet = () => {
    axios({
      method: "get",
      url: process.env.REACT_APP_API_ENDPOINT + "wallet",
      headers: { Authorization: login.token },
    })
      .then((data) => {
        if (data.data.error == false) {
          console.log(data.data);

          set_data_wallet(data.data);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          login.set_token(null);
        }
      });
  };

  React.useEffect(() => {
    // get_data_wallet();
  }, []);

  const create_wallet = () => {
    var data_body = {
      name: "new_wallet_name",
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT + "wallet",
      headers: { Authorization: login.token },
      data: data_body,
    };

    axios(config)
      .then((data) => {
        console.log(data);
        setData(data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          login.set_token(null);
        }
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.color_map.gray,
        height: "100vh",
        // justifyContent: "space-between",
      }}
    >
      {/* <Card style={{ padding: 10, margin: 10 }}>
        <Typography> Vi cua ban</Typography>
        <Box>
          {data_wallet == null
            ? null
            : data_wallet.owner.map((item) => {
                return <Typography>{item.name}</Typography>;
              })}
        </Box>
        <Typography>Vi dang tham gia</Typography>
        <Box>
          {data_wallet == null
            ? null
            : data_wallet.members.map((item) => {
                return <Typography>{item.name}</Typography>;
              })}
        </Box>
        <Button onClick={() => create_wallet()}>Create wallet</Button>
        <Button onClick={() => create_wallet()}>join a wallet</Button>
      </Card> */}

      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 10,
          padding: 10,
        }}
      >
        <Typography>Change password</Typography>
        <TextField
          style={{ marginTop: 10, marginBottom: 10 }}
          autoFocus
          name="password"
          type="password"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
          placeholder="current password"
          value={password}
          onChange={(e) => {
            set_password(e.target.value);
          }}
        />

        <TextField
          name="password"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
          type="password"
          placeholder="new password"
          value={password_1}
          onChange={(e) => {
            set_password_1(e.target.value);
          }}
        />

        <TextField
          name="password"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
          type="password"
          placeholder="confirm new password"
          value={password_2}
          onChange={(e) => {
            set_password_2(e.target.value);
          }}
        />
        <Typography>{change_nav_state}</Typography>

        {password_1 == password_2 && password_1 != "" ? (
          <Button
            style={{ marginTop: 10 }}
            onClick={() => change_password_api()}
          >
            confirm
          </Button>
        ) : null}
      </Card>

      <Card
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          margin: 10,
          marginBottom: 70,
          padding: 10,
        }}
        onClick={() => {
          localStorage.removeItem(data_const.MONOTE_USER_ID);
          localStorage.removeItem(data_const.MONOTE_ACCESS_TOKEN);
          localStorage.removeItem(data_const.MONOTE_USER_NAME);
          login.set_token(null);
        }}
      >
        <Typography color={"red"}>Logout</Typography>
      </Card>
    </div>
  );
}
